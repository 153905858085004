<template>
<form @submit.prevent="" class="dashboardList">
    <div class="list-widget list-dash-items modal-list-body mt-2 mb-2">
        <div class="list-row l-head">
            <div class="list-col l-title">
                <span>عنوان</span>
            </div>
            <div class="list-col l-item">
                <span class="width-fix">ویرایش</span>
            </div>
            <div class="list-col l-item"><span class="width-fix">حذف</span></div>
        </div>
        <div v-for="dashboard in data.dashboards" :key="dashboard.id" class="list-row hover bold">
            <div class="list-col l-title">
                <span>{{ dashboard.title }}</span>
            </div>
            <div class="list-col l-title d-flex">
                <input type="radio" class="option-input radio" id="customControlValidation1" name="default-dash-input" :checked="dashboard.default" @change="changeDefault(dashboard.id)">
                <label for="customControlValidation1" class="d-block m-0">انتخاب به عنوان پیشفرض</label>
            </div>
            <a href="#" class="list-col l-item pointer" @click.prevent="goToRoute(dashboard.id)">
                <i class="uil uil-presentation-edit"></i>
            </a>
            <div class="list-col l-item red-color pointer" @click="removeDashboard(dashboard.id)">
                <i class="uil uil-times"></i>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12">
            <button type="button" class="btn btn-primary rename-dashboard" @click.prevent="saveChanges()">ذخیره تغییرات</button>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت برای مودال لیست کامپوننت ها می باشد
// وضعیت فعلی : غیرفعال
export default {
    name: 'DashboardList',
    props: ['data'],
    components: {},
    data: function () {
        return {
            newDashboard: '',
            default_id: '',
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.newDashboard = '';
        }, { immediate: true });
    },
    methods: {
        // این متد وظیفه افزودن داشبورد جدید را بر عهده دارد
        addDashboard() {
            let api_data = {
                title: this.newDashboard,
            };

            this.$helpers.makeRequest('POST', '/dashboard/store', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'موفق',
                        message: 'داشبورد با موفقیت ایجاد شد',
                    });

                    let dashboard = api_response.data.response.dashboard;
                    this.$parent.emitData('addDashboard', { dashboard: this.$helpers.unbindObject(dashboard) }, true);
                }
            });
        },
        // این متد وظیفه حذف کردن داشبورد را هندل میکند
        removeDashboard(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این داشبورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    this.$helpers.makeRequest('DELETE', '/dashboard/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$parent.emitData('removeDashboard', { id });
                        }
                    });
                }
            });
        },
        // این متد وظیفه تغییر داشبورد پیشفرض را بر عهده دارد
        changeDefault(id) {
            this.default_id = id;
        },
        // این متد وظیفه ذخیره سازی تغییرات مربوط به پیشفرض سازی یک داشبورد را بر عهده دارد
        saveChanges() {
            if (this.default_id) {
                this.$helpers.makeRequest('GET', '/dashboard/set-default/' + this.default_id).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        this.$store.commit('setDefaultDashboard', { default_id: this.default_id });
                    }
                });
            }
            this.$parent.closeModal();
        },
        // این متد مسیر اصلی داشبورد را به ویرایش داشبورد مد نظر تغییر میدهد
        goToRoute(id) {
            this.$parent.closeModal();
            this.$router.push({ name: "DashboardEdit", params: { id } });
        },
    },
}
</script>
